import React, { useState, useEffect } from 'react';
import { Typography, ImageList, ImageListItem, Grid } from '@mui/material';
import { useTranslation } from "react-i18next"

import { Card, CardActions, CardContent, CardMedia, Button } from '@mui/material';

import { SynopsisModuleTeaser } from '../../components/SynopsisModule/SynopsisModule';
import { SpotWeatherModuleMeteoTeaser } from '../../components/SpotWeatherModule/SpotWeatherModuleMeteo';
import  SpotWeatherGraphicOnlyTeaser  from '../../components/SpotWeatherModule/SpotWeatherGraphicOnlyTeaser';
import { SpotWeatherTableOnlyTeaser } from '../../components/SpotWeatherModule/SpotWeatherTableOnlyTeaser';
//import { MapTeaser } from '../../components/Map/Map';



import { PageNumbers } from '../../modules/pageNumbers'
import { useAccessContext } from '../../modules/AccessContext'

import { setImage } from '../../modules/requestHelper'

import useStyles from './dashboardStyles'

const DashBoard = ( { styles, pages, goTo, version, update } ) => {
    const accessContext = useAccessContext()
    const { t } = useTranslation()

    const [tiles, setTiles] = useState([]);

    useEffect(() => {   // init component with tiles
        function addTiles(list, items) {
          items.forEach(item => {
              if (item.subItems) {
                  addTiles(list, item.subItems)
              } else {
                if (item.pageNumber !== PageNumbers.Impressum && item.pageNumber !== PageNumbers.AGB && item.pageNumber !== PageNumbers.SpotWeatherGraphicOnly) {
                  list.push(item)
                }
              }
          })
      }

      let tiles = []
      addTiles(tiles, pages)
      setTiles(tiles)
    }, [pages, update])
//Alle außer die Willkommen Card werden entfernt für den Nutzer mit der ID
    if (accessContext.payload.access.includes("sc_ndrCustomerDtns")) {
      tiles.splice(1,20)
    }
    return (
        <div className={styles.mainComponent}>
            <ImageList cellHeight={322} cols={(accessContext.payload.access.includes("sc_ndrCustomerDtns"))?"3":"4"}>
                { tiles.map((item) =>
                    <ImageListItem key={item.index} cols={1}>
                        <Card className={styles.dashboardCard} variant="outlined">
                          <CardContent>
                            <Typography variant="subtitle1" className={styles.moduleText} align="left" paragraph>
                                { t(item.title ? item.title : item.text, { textArg: item.textArg }) }
                            </Typography>
                            <Typography variant="body2" component="p">
                                { item.pageNumber !== PageNumbers.Home && (
                                    t(item.teaser_text ? item.teaser_text : item.text) 
                                )}
                                { item.pageNumber === PageNumbers.Home && (
                                    t("page_home_teaser", {user: accessContext.payload.name}) 
                                )}
                            </Typography>
                            { item.pageNumber === PageNumbers.Home && (<DashBoardTeaser styles={styles} version={version} />)}

                            { item.pageNumber === PageNumbers.Synopsis && (<SynopsisModuleTeaser styles={styles} update={update}/>) }
                            { item.pageNumber === PageNumbers.SpotWeatherMeteo && (<SpotWeatherModuleMeteoTeaser styles={styles} loopIndex={item.loopIndex} update={update}/>) }
                            { item.pageNumber === PageNumbers.SpotWeatherTableOnly && (<SpotWeatherTableOnlyTeaser styles={styles} loopIndex={item.loopIndex} update={update}/>) }
                            { item.pageNumber === PageNumbers.SpotWeatherGraphicOnly && (<SpotWeatherGraphicOnlyTeaser styles={styles} loopIndex={item.loopIndex} update={update}/>) }
                            { item.pageNumber === PageNumbers.Map }
                            { item.pageNumber === PageNumbers.WeatherMap }
                          </CardContent>
                          <CardActions>
                                { item.pageNumber !== PageNumbers.Home && (
                                    <Button size="small" onClick={() => goTo(item.pageNumber, { loopIndex: item.loopIndex })}>{ t("dashboard_more") }</Button>
                                )} 
                          </CardActions>
                        </Card>                        
                    </ImageListItem>
                )}
            </ImageList>
        </div>
    )
}

/**
        <Typography variant="caption" component="p" color="textSecondary" className={myStyles.dashboardCardHomeDate}>
          { t("page_home_date", {now: new Date()}) }
        </Typography>

 * 
 */

const DashBoardTeaser = ( { version } ) => {
    const myStyles = useStyles()
    const { t } = useTranslation()
    const accessContext = useAccessContext()
    const [logo, setLogo] = useState(undefined)
  
    let i = new Image();

    useEffect(() => {   // init teaser with first image
      if (accessContext.configuration.customerContext) {
        setImage(accessContext, accessContext.configuration?.logo, setLogo)
        // i.src = logo.src
        // console.log(i.width)
      }
    }, [accessContext])

 

  //   i.onload = function(){
  //     console.log(i.width +"  " + i.height)
  //  //   alert(i.width + ", " + i.height);
  //   };
    
    // console.log(i)

    // console.log(logo.src)
    return (
      <div>
        <Grid container justifyContent="center" spacing={2}>
            <Grid item xs={6}>
            { logo && (
              <>
                <CardMedia
                  className={myStyles.dashboardCardMedia}
                  component="img"
                  image={logo.src}
                />
                <br/>
                <Typography variant="caption" component="p">
                    {  t("page_home_welcome_version") + version }
                </Typography>
              </>
            ) }
            </Grid>
            <Grid item xs={6}>
            {(accessContext.payload.access.includes("data_stolpe")) ? 
                <Typography variant="caption" component="p">
                  { t("page_home_stolpe_address") }
                </Typography>
                : ""
              }
                <Typography variant="caption" component="p" className={myStyles.dashboardRightColumn}>
                    { t("page_home_welcome_text") }
                </Typography>
                <Typography className={myStyles.dashboardRightColumnSpacer}>
                </Typography>
                <Typography variant="caption" component="p">
                { (accessContext.payload.access.includes("sc_ndrCustomerDtns")) ? 
                    t("page_home_welcome_email_ndr")
                  : t("page_home_welcome_email") }
                </Typography>
                <Typography variant="caption" component="p">
                    { (accessContext.payload.access.includes("sc_ndrCustomerDtns")) ? 
                    t("page_home_welcome_tel_ndr")
                  : t("page_home_welcome_tel") }
                </Typography>
                {(accessContext.payload.access.includes("sc_ndrCustomerDtns")) ? 
                <Typography variant="caption" component="p">
                  { t("page_home_welcome_tel_ndr_times") }
                </Typography>
                : ""
              }
            </Grid>
        </Grid>
      </div>
    )
  }


export { DashBoard, DashBoardTeaser }
