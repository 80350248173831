import i18next from "i18next"
import { initReactI18next } from "react-i18next"

const resources = {
    en: {
        translation: {
            login_title: "WetterWelt Login",
            login_text: "Please enter your login data:",
            login_label_name: "Name",
            login_label_password: "Password",
            login_action_login: "Login",
            login_failed: "Login failed.",

            appbar_filter: "Filter ...",
            appbar_language: "Language",
            menubar_logout: "Logout",
            footer_contact: "Contact",

            user_settings: "Settings",

            page_home: "Welcome", page_home_teaser: "Welcome, {{user}}!",
            page_home_date: "{{now, dateTime}}",

            page_customers_text: "Customers",

            synopsis_image_title_1: "today",
            synopsis_image_title_2: "tomorrow",
            synopsis_image_title_3: "day after tomorrow",

            error_data_api_request: "Error in data request: ",

            format_datetime: "{{aDate, dateTimeTable}}",

            api_lang_suffix: "/en",
        },
    },
    de: {
        translation: {
            login_title: "WetterWelt Anmeldung",
            login_text: "Bitte geben Sie Ihre Anmelde Daten ein:",
            login_label_name: "Name",
            login_label_password: "Passwort",
            login_action_login: "Anmelden",
            login_failed: "Anmeldung fehlgeschlagen.",
            login_failed_sorry: "Das hätte nicht passieren dürfen: Anmeldung fehlgeschlagen aufgrund eines internen Fehlers.",

            appbar_filter: "Filter ...",
            appbar_language: "Sprache",
            menubar_logout: "Abmelden",
            menubar_reload: "Aktualisieren",
            footer_contact: "Kontakt",
            footer_disclaimer: "Disclaimer",
            footer_impressum: "Impressum",
            footer_agb: "AGB",

            dashboard_more: "mehr",

            user_settings: "Einstellungen",

            page_home: "METEO MONITOR", page_home_teaser: "Willkommen, {{user}}!",
            page_home_date: "{{now, dateTime}}",
            page_home_welcome_text: "Bei Fragen oder Problemen wenden Sie sich bitte an:",
            page_home_welcome_email: "service@wetterwelt.net",
            page_home_welcome_email_ndr: "mail@wetterwelt.net",
            page_home_welcome_tel: " ",
            page_home_welcome_tel_ndr: "(0431) 97 99 08 27",
            page_home_welcome_tel_ndr_times: "täglich 5:30 bis 18 Uhr",
            page_home_welcome_version: "Version ",


            page_home_stolpe_address: "An der Straßenmeisterei 1, 24601 Stolpe",
            


            page_weathermap_text: "Wetterkarten",
            page_weathermap_teaser: "Wetterkarten Teaser",
            page_singlespotweather_text: "Single Spotweather",
            page_singlespotweather_teaser: " ",

            page_synopsis_text: "Wetterlage", 
            page_synopsis_teaser: "Die Wetterlage in Ihrer Region",
            page_spotweather_group_text: "Spotweather",
            page_spotweather_location_group_text: "{{textArg}}",

            page_spotweather_table: "Tabelle",
            page_spotweather_graphic: "Grafik",
            page_spotweather_text: "{{textArg}}", 
            page_spotweather_teaser: "Die wichtigsten Wetter Parameter im Detail",
            page_spotweather_title: "Spot Weather \"{{textArg}}\"",
            page_spotweather_table_text: "{{textArg}}", 
            page_spotweather_table_teaser: "Die wichtigsten Wetter Parameter (Tabelle)",
            page_spotweather_table_title: "Spot Weather \"{{textArg}}\" Tabelle",
            page_spotweather_graphic_text: "{{textArg}}", 
            page_spotweather_graphic_teaser: "Die wichtigsten Wetter Parameter (Grafik)",
            page_spotweather_graphic_title: "Spot Weather \"{{textArg}}\" Grafik",
            page_riskassessment_text: "RiskAssessment", 
            page_riskassessment_teaser: "Aktuelle Risiken im Detail",
            page_riskassessment_title: "Risk Assessment",
            page_spotweathermap_text: "Charts", 
            page_spotweathermap_teaser: "Ihre Daten im Überblick",

            page_group_service: "WW Service",
            page_products_text: "Produkte",
            page_customers_text: "Kunden",
            page_accounts_text: "Accounts",
            page_contacts_text: "Kontakte",
            page_purchases_text: "Käufe",
            page_requestInfoSps_text: "Request Infos",
            page_dispatchLogs_text: "Dispatch Logs",

            page_group_spotweather_admin: "Spot Weather (Admin.)",
            page_spotweather_requests_text: "Spot Weather Requests",
            page_spotweather_locations_text: "Spot Weather Locations",
            page_spotweather_parameters_text: "Spot Weather Parameters (Advanced)",
            page_spotweathers_text: "Spot Weathers",

            page_vessels_text: "Vessels",
            page_voyages_text: "Voyages",
            page_routes_text: "Routes",
            page_waypoints_text: "Waypoints",

            page_group_protocols: "Protokolle",
            page_protocol_service_text: "Service",
            page_protocol_service_title: "Service Protokoll",
            page_meteo_model_import_path_text: "MeteoDB: Model-Import-Paths",

            page_agrartext_text: "Agrar Texte",
            page_agrartext_text_all: "Alles",
            page_agrartext_text_frühling: "Frühling",
            page_agrartext_text_sommer: "Sommer",
            page_agrartext_text_herbst: "Herbst",
            page_agrartext_text_winter: "Winter",

            page_biotext_text: "Bio Texte",
            page_biotext_text_all: "Alles",
            page_biotext_text_frühling: "Frühling",
            page_biotext_text_sommer: "Sommer",
            page_biotext_text_herbst: "Herbst",
            page_biotext_text_winter: "Winter",
            page_biotext_text_allgemein: "Allgemein",
            page_biotext_text_schoenwetter_angenehm: "Schönwetter_angenehm",
            page_biotext_text_extrem_mild_sehr_heiss: "Extrem mild sehr heiß",
            page_biotext_text_naehe_zum_tiefauslaeufer: "Nähe zum Tiefausläufer",
            page_biotext_text_front_und_tiefkerngebereich: "Front- und Tiefkernbereich",
            page_biotext_text_warmsektor: "Warmsektor",
            page_biotext_text_rueckseitenwetter: "Rückseitenwetter",
            page_biotext_text_nasskalt_nebel: "Nasskalt Nebel",
            page_biotext_text_strenge_winterlage: "Strenge Winterlage",
            page_biotext_text_egal: "Egal",

            page_ndr_customer_dtn_text_wetterdaten: "aktuelle Texte und Daten",
            page_ndr_customer_dtn_text: "Alles",
            page_ndr_customer_dtn_text_messwerte_norddeutschland: "Stationsdaten: Messwerte/Norddeutschland",
            page_ndr_customer_dtn_text_messwerte_d_eu_welt: "Stationsdaten: Messwerte/D EU Welt",
            page_ndr_customer_dtn_text_vorhersagen_dito: "Stationsdaten: Vorhersagen",
            page_ndr_customer_dtn_text_texte_norddeutschland: "Texte/Norddeutschland",
            page_ndr_customer_dtn_text_texte_norddeutsche_bundeslaender: "Texte/Norddeutsche Bundesländer",
            page_ndr_customer_dtn_text_text_deutschland_nacht: "Text/Deutschland Nacht",
            page_ndr_customer_dtn_text_text_wetterspezial: "Text/Wetterspezial",
            page_ndr_customer_dtn_text_text_niedersachsen_regional: "Text/Niedersachsen Regional",
            page_ndr_customer_dtn_text_text_agrar_bio_pollen: "Text/Agrar Bio Pollen",
            page_ndr_customer_dtn_text_text_wasser: "Text/Wasser",
            page_ndr_customer_dtn_text_text_wintersport: "Stationsdaten: Messwerte/Wintersport",
            page_ndr_customer_dtn_text_ndr_teletext: "NDR Teletext",
            page_ndr_customer_dtn_text_ndr_online: "NDR Online",
            page_ndr_customer_dtn_text_info: "Info",
            page_ndr_customer_dtn_text_unwetterwarnung: "Text/Unwetterwarnung",
            page_ndr_customer_dtn_text_begriffserklaerung: "Begriffserklärung",
            page_ndr_customer_dtn_text_windlegende: "Wind-Legende",
            page_ndr_customer_dtn_text_kontakt: "Kontakt",

            page_group_geodata: "Geo Data",
            page_geodata_ports_text: "WW Ports",

            page_group_test_graphic: "Diagramme / Bibl. im Test",
            page_spotweather_text_d3: "SpotWeather D3",
            page_spotweather_text_victory: "SpotWeather Victory", page_spotweather_teaser_victory: "Die wichtigsten Wetter Parameter im Detail",
            page_spotweather_text_vega: "SpotWeather Vega",
            page_spotweather_text_nivo: "SpotWeather Nivo",
            page_spotweather_text_recharts: "SpotWeather Recharts",
            page_spotweather_text_anni_1: "SpotWeather Anni 1",
            page_spotweather_text_anni_2: "SpotWeather Anni 2",

            page_group_datavis: "Test Data Visualization",
            page_datavis_samples_text:  "Raster Geo Map (react-leaflet)",
            page_datavis_samples_text3: "Polygon Geo Map 2 (d3-geo)",

            page_group_bighistory: "Big History",
            page_bighistory_taxonomyFamilies_text: "Taxonomy Families",
            page_bighistory_TaxonomyGenera_text: "Taxonomy Genera",
            page_bighistory_O4PaleoMaps_text: "Overview Paleo Maps",
            page_bighistory_PaleoMaps_text: "Highres Paleo Maps",
            page_bighistory_AstroSuns_text: "Astro Objects",
            page_bighistory_Spacecrafts_text: "Spacecrafts",

            synopsis_image_title_1: "Wetterlage Tag 1",
            synopsis_image_title_2: "Wetterlage Tag 2",
            synopsis_image_title_3: "Wetterlage Tag 3",
            synopsis_image_title_4: "Wetterlage Tag 4",
            synopsis_image_title_5: "Wetterlage Tag 5",
            synopsis_image_title_6: "Wetterlage Tag 6",
            synopsis_image_title_7: "Wetterlage Tag 7",
            synopsis_text_title: "Wetterlage",
            synopsis_warning_title: "Warnung",
            synopsis_comment_title: "Vorwarnung",

            spotweather_data_title: "Wetter Daten",
            spotweather_timeline_title: "Zeitreihen",

            table_header_time_utc: "Zeit (UTC)",
            table_header_time_lt: "Zeit (LT)",

            // Data Api Params
            table_header_dd: "Windrichtung",
            table_header_ddDegree: "Windrichtung",
            table_header_ffms: "Windgeschw.",
            table_header_ffgms: "Böen",
            table_header_tt: "Temperatur",

            unit_dd: "",
            unit_ddDegree: "°",
            unit_ffms: "m/s",
            unit_ffgms: "m/s",
            unit_tt: "°C",

            // Meteo DB Params
            // Als Tabellen Header
            table_header_param_T: "Temperatur",
            table_header_param_T0: "Boden Temperatur",
            table_header_param_PPPP: "Luftdruck",
            table_header_param_FF: "Windgeschw. 10m",
            table_header_param_FFDIR: "Windrichtung 10m",
            table_header_param_FFG: "Böen 10m",
            table_header_param_FF_LOG_50_M: "Windgeschw. 50m",
            table_header_param_FFG_LOG_50_M: "Böen 50m",
            table_header_param_FFDIR_LOG_50_M: "Windrichtung 50m",
            table_header_param_WW: "Wetter",
            table_header_param_VV: "Sicht",
            table_header_param_CUR : "Strömungs Geschw.",
            table_header_param_CURDIR: "Strömungs Richtung",
            "table_header_param_W-HSSW": "Wellenhöhe",
            "table_header_param_W-WSHMAX": "Max. Wellenhöhe",
            "table_header_param_W-DMEAN": "Wellen Richtung",
            "table_header_param_W-DSW": "Wellen Richtung",
            "table_header_param_W-PSEA": "Wellen Periode",
            "table_header_param_W-PMEAN": "Eff. Wellen Periode",
            "table_header_param_W_HSSW": "Wellenhöhe",
            "table_header_param_W_WSHMAX": "Max. Wellenhöhe",
            "table_header_param_W_DMEAN": "Wellen Richtung",
            "table_header_param_W_DSW": "Wellen Richtung",
            "table_header_param_W_PSEA": "Wellen Periode",
            "table_header_param_W_PMEAN": "Eff. Wellen Periode",
            table_header_param_CURDIRTO: "Strömung nach",
            table_header_param_U: "U Komponente Wind",
            table_header_param_V: "V Komponente Wind",
            table_header_param_RH: "Relative Feuchte",
            table_header_param_NCL: "Eff. Wolken Bedeckung",
            table_header_param_RRR1: "Niederschlag 1 Stunde",
            table_header_param_RRR3: "Niederschlag 3 Stunden",
            table_header_param_RRR6: "Niederschlag 6 Stunden",
            table_header_param_RRR12: "Niederschlag 12 Stunden",
            table_header_param_RRR24: "Niederschlag 24 Stunden",
            table_header_param_SNOWF1: "Schneefall 1 Stunde",
            table_header_param_SNOWF6: "Schneefall 6 Stunden",
            table_header_param_SNOD: "Schneehöhe",
            "table_header_param_P-WEATHER": "Wetterzustand",
            "table_header_param_P_WEATHER": "Wetterzustand",

            // Standard Bezeichnungen
            name_param_Warning: "Warnstufe Wellenhöhe",
            name_param_WarningWind: "Warnstufe Windg.",

            name_param_T: "Temperatur",
            name_param_T0: "Boden Temperatur",
            name_param_PPPP: "Luftdruck",
            name_param_FF: "Windgeschw. 10m",
            name_param_FFDIR: "Windrichtung 10m",
            name_param_FFG: "Böen 10m",
            name_param_FF_LOG_50_M: "Windgeschw. 50m",
            name_param_FFG_LOG_50_M: "Böen 50m",
            name_param_FFDIR_LOG_50_M: "Windrichtung 50m",
            name_param_WW: "Wetter",
            name_param_VV: "Sicht",
            name_param_CUR : "Strömungsgeschw.",
            name_param_CURDIR: "Strömungsrichtung",
            "name_param_W-HSSW": "Sign. Wellenhöhe",
            "name_param_W-WSHMAX": "Max. Wellenhöhe",
            "name_param_W-DMEAN": "Wellenrichtung",
            "name_param_W-PSEA": "Wellenperiode",
            "name_param_W-PMEAN": "Eff. Wellenperiode",
            "name_param_W_HSSW": "Sign. Wellenhöhe",
            "name_param_W_WSHMAX": "Max. Wellenhöhe",
            "name_param_W_DMEAN": "Wellenrichtung",
            "name_param_W_PSEA": "Wellenperiode",
            "name_param_W_PMEAN": "Eff. Wellenperiode",
            name_param_CURDIRTO: "Strömung nach",
            name_param_U: "U Komponente Wind",
            name_param_V: "V Komponente Wind",
            name_param_RH: "Relative Feuchte",
            name_param_NCL: "Eff. Wolken Bedeckung",
            name_param_RRR1: "Niederschlag 1 Stunde",
            name_param_RRR3: "Niederschlag 3 Stunden",
            name_param_RRR6: "Niederschlag 6 Stunden",
            name_param_RRR12: "Niederschlag 12 Stunden",
            name_param_RRR24: "Niederschlag 24 Stunden",
            name_param_SNOWF1: "Schnefall 1 Stunde",
            name_param_SNOWF6: "Schneefall 6 Stunden",
            name_param_SNOD: "Schneehöhe",
            "name_param_P-WEATHER": "Wetterzustand",
            "name_param_P_WEATHER": "Wetterzustand",

            param_unit_kn: 'kn',
            param_unit_hPa: 'hPa',
            param_unit_K: "°K",
            "param_unit_°C": '°C',
            "param_unit_m/s": 'm/s',
            param_unit_m: "m",
            param_unit_km: "km",
            "param_unit_-": "-",
            "param_unit_°": "°",
            param_unit_s: "s",
            "param_unit_%": "%",
            param_unit_mm: "mm",
            "param_unit_km/h": "km/h",
            param_unit_bft: "bft",
            param_unit_de: "de",
            
            timezone_utc: "UTC",
            timezone_lt: "LT",

            legend_title: "Legende",
            legend_symbol_hint: "Hinweis: Wettersymbole werden in der Grafik nur im Fall von Warnungen angezeigt!",

            map_spotweather_locations: "Spot Weather",

            precipitation_radar: "Niederschlagsradar",

            error_data_api_request: "Fehler beim Daten Abruf: ",

            format_datetime: "{{aDate, dateTime}}",
            format_datetimeTable: "{{aDate, dateTimeTable}}",
            format_xAxisNewDay: "{{aDate, xAxisNewDay}}",
            format_xAxisTime: "{{aDate, xAxisTime}}",

            api_lang_suffix: "/de",


            //Impressum
            ww_company_name: "WetterWelt GmbH",
            ww_street: "Schauenburgerstr. 116",
            ww_postcode_city: "24118 Kiel",
            ww_phone: "Telefon: +49 431 97 99 08 0",
            ww_fax: "Telefax: +49 431 97 99 08 29",
            ww_email: "E-Mail: service@wetterwelt.net",
            ww_internet: "Internet: www.wetterwelt.de",
            ww_manager: "Geschäftsführer: Dr. Meeno Schrader",
            ww_registry_court: "Registergericht: Amtsgericht Kiel",
            ww_registry_number: "Registernummer: HRB 6604 KI",
            ww_tax: "USt.-Id.-Nr.: DE 814 067 737",
        },
    },
}

i18next
    .use(initReactI18next)
    .init({
        resources,
        lng: "de",
        interpolation: {
            escapeValue: false,
            format: function (value, format, lng) {
                if (format === "date") {
                  return new Intl.DateTimeFormat(lng).format(value);
                }
                if (format === "dateTime") {
                    const options = { month: "2-digit", day: "2-digit", year: "numeric", hour: "numeric", minute: "numeric", timeZone: 'UTC' }
                    return new Intl.DateTimeFormat(lng, options).format(value);
                }
                if (format === "dateTimeTable") {
                    const options = { month: "2-digit", day: "2-digit", year: "2-digit", hour: "numeric", minute: "numeric", timeZone: 'UTC' }
                    return new Intl.DateTimeFormat(lng, options).format(value);
                }
                if (format === "xAxisNewDay") {
                    const options = { day: "2-digit", weekday: "short", timeZone: 'UTC' }
                    return new Intl.DateTimeFormat(lng, options).format(value);
                }
                if (format === "xAxisTime") {
                    const options = { hour: "numeric", minute: "numeric", timeZone: 'UTC'}
                    return new Intl.DateTimeFormat(lng, options).format(value);
                }
                return value;
            },    
        },
        fallbackLng: "de",
    });

export default i18next;